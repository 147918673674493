<template>
    <div>
        <slot
            :countries="countries"
            :update-countries="updateCountries"
            :project-types="projectTypes"
            :update-project-types="updateProjectTypes"
            :series="series"
            :update-series="updateSeries"
            :products="products"
            :update-products="updateProducts"
            :finishes="finishes"
            :update-finishes="updateFinishes"
            :coatings="coatings"
            :update-coatings="updateCoatings"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    countries: {
        type: Array,
        required: true,
    },
    projectTypes: {
        type: Array,
        required: true,
    },
    series: {
        type: Array,
        required: true,
    },
    products: {
        type: Array,
        required: true,
    },
    finishes: {
        type: Array,
        required: true,
    },
    coatings: {
        type: Array,
        required: true,
    },
});

const countries = ref(props.countries);

const updateCountries = (event) => {
    if (countries.value.includes(event.target.value)) {
        countries.value = countries.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    countries.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};


const projectTypes = ref(props.projectTypes);

const updateProjectTypes = (event) => {
    if (projectTypes.value.includes(event.target.value)) {
        projectTypes.value = projectTypes.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    projectTypes.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const series = ref(props.series);

const updateSeries = (event) => {
    if (series.value.includes(event.target.value)) {
        series.value = series.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    series.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const products = ref(props.products);

const updateProducts = (event) => {
    if (products.value.includes(event.target.value)) {
        products.value = products.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    products.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const finishes = ref(props.finishes);

const updateFinishes = (event) => {
    if (finishes.value.includes(event.target.value)) {
        finishes.value = finishes.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    finishes.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const coatings = ref(props.coatings);

const updateCoatings = (event) => {
    if (coatings.value.includes(event.target.value)) {
        coatings.value = coatings.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    coatings.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (countries.value.length) {
        searchParams.append('region', countries.value.join(','));
    }

    if (projectTypes.value.length) {
        searchParams.append('categories', projectTypes.value.join(','));
    }

    if (products.value.length) {
        searchParams.append('product', products.value.join(','));
    }

    if (series.value.length) {
        searchParams.append('series', series.value.join(','));
    }

    if (finishes.value.length) {
        searchParams.append('finish', finishes.value.join(','));
    }

    if (coatings.value.length) {
        searchParams.append('coating', coatings.value.join(','));
    }

    window.location.href = `/projects?${searchParams.toString()}`;
};

</script>

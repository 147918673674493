import {
    defineComponent, h, nextTick, ref, watch, watchEffect,
} from 'vue';

import { useProductStore } from '@stores/products';

export const ProductSamples = defineComponent({
    name: 'ProductSamples',

    props: {
        variants: {
            type: Array,
            required: true,
        },
    },

    setup(props, { slots }) {
        const productStore = useProductStore();

        const loading = ref(false);
        const selectedVariant = ref(props.variants[0]);

        const timberFinishes = ref([]);

        props.variants.forEach((variant) => {
            if (timberFinishes.value.some((timberFinish) => { return timberFinish === variant.timberFinish; })) {
                return;
            }

            timberFinishes.value.push(variant.timberFinish);
        });

        const selectedTimberFinish = ref(props.variants[0].timberFinish);

        const timberColours = ref([]);

        props.variants.forEach((variant) => {
            if (timberColours.value.some((timberColour) => { return timberColour.label === variant.timberColour; })) {
                return;
            }

            timberColours.value.push({
                label: variant.timberColour,
                image: variant.image,
            });
        });

        const selectedTimberColour = ref(props.variants[0].timberColour);

        const profiles = ref([]);

        props.variants.forEach((variant) => {
            if (profiles.value.some((profile) => { return profile === variant.profile; })) {
                return;
            }

            if (!variant.profile) {
                return;
            }

            profiles.value.push(variant.profile);
        });

        const selectedProfile = ref('');

        const availableTimberColours = ref([]);
        const availableProfiles = ref([]);

        watchEffect(() => {
            if (selectedTimberFinish.value) {
                availableTimberColours.value = [];
                availableProfiles.value = [];

                props.variants.forEach((variant) => {
                    if (variant.timberFinish !== selectedTimberFinish.value || !variant.inStock) {
                        return;
                    }

                    availableTimberColours.value.push(variant.timberColour);
                });

                props.variants.forEach((variant) => {
                    if (variant.timberColour !== selectedTimberColour.value) {
                        return;
                    }

                    if (variant.timberFinish !== selectedTimberFinish.value) {
                        return;
                    }

                    if (availableProfiles.value.some((profile) => { return profile === variant.profile; })) {
                        return;
                    }

                    availableProfiles.value.push(variant.profile);
                });
            }

            if (selectedTimberColour.value && selectedTimberFinish.value) {
                [selectedVariant.value] = props.variants.filter((variant) => {
                    return selectedTimberColour.value === variant.timberColour && selectedTimberFinish.value === variant.timberFinish;
                });
            }
        });

        watch(selectedTimberFinish, (value, oldValue) => {
            if (value === oldValue) {
                return;
            }

            [selectedTimberColour.value] = availableTimberColours.value;
            selectedProfile.value = '';
        });

        watch(selectedTimberColour, (value, oldValue) => {
            if (value === oldValue) {
                return;
            }

            selectedProfile.value = '';
        });

        const messages = ref([]);

        const addToCart = (e) => {
            loading.value = true;

            if (profiles.value.length && !selectedProfile.value) {
                const message = {
                    type: 'error',
                    text: 'Please select a profile',
                };

                if (messages.value.some((item) => {
                    return item.text === message.text;
                })) {
                    return;
                }

                messages.value.push(message);

                return;
            }

            nextTick(() => {
                e.target.closest('form').submit();
            });

            // alert(selectedVariant.value.price);
        };

        const getSwatchImage = (colour) => {
            const matchingVariant = props.variants.find((variant) => {
                return variant.timberColour === colour.label && variant.timberFinish == selectedTimberFinish.value;
            });

            if (!matchingVariant) {
                return colour.image;
            }

            return matchingVariant.image;
        };

        const checkProfileStock = (profile) => {
            const variant = props.variants.find((variant) => {
                return variant.profile === profile && variant.timberColour === selectedTimberColour.value;
            });

            return !variant?.inStock;
        };

        return () => {
            return h('div', {}, slots.default({
                loading,
                timberFinishes,
                selectedTimberFinish,
                timberColours,
                availableTimberColours,
                selectedTimberColour,
                profiles,
                availableProfiles,
                selectedProfile,
                selectedVariant,
                messages,
                addToCart,
                getSwatchImage,
                checkProfileStock,
            }));
        };
    },
});

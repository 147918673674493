<template>
    <form v-if="!result">
        <h4 class="h4">{{ products.calculatorHeading ?? 'Calculator' }}</h4>

        <div class="mb-4">
            <label for="timber-select" class="font-semibold mb-2 block">Choose Timber*</label>

            <select id="timber-select" v-model="selectedProductId" class="fui-select fui-i" required>
                <option value="">Select Product</option>

                <option
                    v-for="row in products" :key="row.id" :value="row.product.id"
                >
                    {{ row.product.title }}
                </option>
            </select>
        </div>

        <div class="mb-4">
            <label for="profile-select" class="font-semibold mb-2 block">Choose Profile*</label>

            <select id="profile-select" v-model="selectedProfile" class="fui-select fui-i" required>
                <option value="">Select Profile</option>

                <option
                    v-for="row in availableProfiles" :key="row.id" :value="row.id"
                >
                    {{ row.profile }} - {{ row.width }}x{{ row.thickness }}mm
                </option>
            </select>
        </div>


        <div class="mb-4">
            <label for="sqm" class="font-semibold mb-2 block">Enter Square Metres*</label>

            <input id="sqm" v-model="meters" type="number" placeholder="(metres squared)" class="w-full text-sm fui-input fui-i" required>
        </div>

        <div class="mb-4">
            <button type="submit" class="btn btn-primary" @click.prevent="calculate">{{ calculatorButtonText ?? 'Calculate' }}</button>
        </div>

        <p class="text-xs">* Required</p>
    </form>

    <div v-else>
        <div class="grid grid-cols-1 gap-2">
            <h4 class="h4">Results</h4>

            <div class="totals grid grid-cols-1 gap-0">
                <div class="total flex justify-between border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Product:</p>
                    <p id="product-name" class="m-0 text-right font-bold">{{ result.product }}</p>
                </div>

                <div class="total flex justify-between border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Profile:</p>
                    <p id="profile-name" class="m-0 text-right font-bold">{{ result.profile }}</p>
                </div>

                <div class="total grid grid-cols-4 gap-0 border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Lineal Metres:</p>
                    <p id="product-meters" class="m-0 text-right font-bold">{{ round(result.lineal, 2) }}</p>
                </div>

                <div class="total grid grid-cols-4 gap-0 border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Total Approximate Weight - KG:</p>
                    <p id="product-weight" class="m-0 text-right font-bold">{{ round(result.weight, 2) }}</p>
                </div>

                <div class="total grid grid-cols-4 gap-0 border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Fixings - Pieces:</p>
                    <p id="product-fixing" class="m-0 text-right font-bold">{{ round(result.fixing, 2) }}</p>
                </div>

                <div v-if="result.oneCoat > 0" class="total grid grid-cols-4 gap-0 border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Oil - Litres (second coat to front face installed):</p>
                    <p id="product-one-coat" class="m-0 text-right font-bold">{{ round(result.oneCoat, 2) }}</p>
                </div>

                <div v-if="result.twoCoats > 0" class="total grid grid-cols-4 gap-0 border-b border-primary-900 py-2 items-center">
                    <p class="col-span-3">Oil - Litres (one coat all sides + two coats face):</p>
                    <p id="product-two-coat" class="m-0 text-right font-bold">{{ round(result.twoCoats, 2) }}</p>
                </div>
            </div>

            <div class="">
                <div class="flex space-x-4 my-4">
                    <button type="button" class="btn btn-primary" @click.prevent="openForm">Enquire Now</button>

                    <span class="btn btn-primary" @click.prevent="reset">Reset</span>
                </div>

                <p class="text-xs col-span-2">Note: The figures given here include 10% wastage and are approximate only. Final order quantities are the buyer’s responsibility and should be checked and verified prior to order placement. Abodo will not accept liability for over or under-ordered items. Thank you!</p>
            </div>
        </div>
    </div>

    <transition-root appear :show="showForm" as="template">
        <hui-dialog as="div" class="relative z-10" @close="showForm = false">
            <transition-child
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25"></div>
            </transition-child>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <transition-child
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <hui-dialog-panel
                            class="transform overflow-y-auto md:w-1/2 max-w-screen-lg h-[80vh] bg-white text-left align-middle shadow-xl transition-all relative"
                        >
                            <svg class="cursor-pointer absolute top-5 right-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" @click="showForm = false"><path d="M317.7 402.3c3.125 3.125 3.125 8.188 0 11.31c-3.127 3.127-8.186 3.127-11.31 0L160 267.3l-146.3 146.3c-3.127 3.127-8.186 3.127-11.31 0c-3.125-3.125-3.125-8.188 0-11.31L148.7 256L2.344 109.7c-3.125-3.125-3.125-8.188 0-11.31s8.188-3.125 11.31 0L160 244.7l146.3-146.3c3.125-3.125 8.188-3.125 11.31 0s3.125 8.188 0 11.31L171.3 256L317.7 402.3z" /></svg>

                            <div class="flex flex-col">
                                <div class="p-6 lg:p-12">
                                    <h2 class="h2 mb-6">Enquiry</h2>

                                    <slot name="form"></slot>
                                </div>
                            </div>
                        </hui-dialog-panel>
                    </transition-child>
                </div>
            </div>
        </hui-dialog>
    </transition-root>
</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
} from '@headlessui/vue';

export default {
    name: 'ProductCalculator',

    components: {
        TransitionRoot,
        TransitionChild,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogOverlay: DialogOverlay,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
    },

    props: {
        calculatorData: {
            type: Object,
            required: true,
        },
        calculatorButtonText: {
            type: String,
            default: 'Calculate',
        },
        productId: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            selectedProductId: this.productId === 0 ? '' : this.productId,
            selectedProfile: '',
            meters: null,
            availableProfiles: [],
            result: null,
            showForm: false,
        };
    },

    computed: {
        products() {
            const products = [];

            this.calculatorData.forEach((row) => {
                if (products.some((productsRow) => { return productsRow.product.id === row.product.id; })) {
                    return;
                }

                products.push(row);
            });

            return products;
        },
    },

    watch: {
        selectedProductId(value) {
            if (value === 0) {
                return;
            }

            this.availableProfiles = this.getAvailableProfiles();
        },
    },

    mounted() {
        if (this.productId === 0) {
            return;
        }

        this.availableProfiles = this.getAvailableProfiles();
    },

    methods: {
        getAvailableProfiles() {
            return this.calculatorData.filter((row) => {
                return row.product.id === this.selectedProductId;
            });
        },

        async calculate(event) {
            const isValid = event.target.closest('form').reportValidity();

            if (!isValid || this.meters === null) {
                return;
            }

            const response = await fetch(`/products/calculator?product=${this.productId}&meters=${this.meters}&profile=${this.selectedProfile}`);

            const result = await response.json();

            this.result = result;
        },

        round(value, decimals) {
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },

        openForm() {
            this.showForm = true;

            this.$nextTick(() => {
                const $form = document.querySelector('.calculator-form .fui-form');
                const csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
                const formHandle = $form.querySelector('input[name="handle"]').value;

                const formie = window.Formie ?? null;

                if (formie) {
                    formie.initForm($form);

                    // Update CSRF input
                    fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
                        .then((result) => { return result.json(); })
                        .then((result) => { csrfInput.outerHTML = result.csrf.input; });
                }

                const notesField = document.querySelector('[data-fui-id="pricing-form-notes"]') ?? document.querySelector('[data-fui-id="pricing-form-au-notes"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-notes"]') ?? document.querySelector('[data-fui-id="pricing-form-row-notes"]');
                const productNameField = document.querySelector('[data-fui-id="pricing-form-product"]') ?? document.querySelector('[data-fui-id="pricing-form-au-product"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-product"]') ?? document.querySelector('[data-fui-id="pricing-form-row-product"]');
                const productProfileField = document.querySelector('[data-fui-id="pricing-form-profile"]') ?? document.querySelector('[data-fui-id="pricing-form-au-profile"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-profile"]') ?? document.querySelector('[data-fui-id="pricing-form-row-profile"]');
                const productLinealField = document.querySelector('[data-fui-id="pricing-form-lineal"]') ?? document.querySelector('[data-fui-id="pricing-form-au-lineal"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-lineal"]') ?? document.querySelector('[data-fui-id="pricing-form-row-lineal"]');
                const productWeightField = document.querySelector('[data-fui-id="pricing-form-weight"]') ?? document.querySelector('[data-fui-id="pricing-form-au-weight"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-weight"]') ?? document.querySelector('[data-fui-id="pricing-form-row-weight"]');
                const productFixingField = document.querySelector('[data-fui-id="pricing-form-fixing"]') ?? document.querySelector('[data-fui-id="pricing-form-au-fixing"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-fixing"]') ?? document.querySelector('[data-fui-id="pricing-form-row-fixing"]');
                const productOneCoatField = document.querySelector('[data-fui-id="pricing-form-one-coat"]') ?? document.querySelector('[data-fui-id="pricing-form-au-one-coat"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-one-coat"]') ?? document.querySelector('[data-fui-id="pricing-form-row-one-coat"]');
                const productTwoCoatsField = document.querySelector('[data-fui-id="pricing-form-two-coats"]') ?? document.querySelector('[data-fui-id="pricing-form-au-two-coats"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-two-coats"]') ?? document.querySelector('[data-fui-id="pricing-form-row-two-coats"]');
                const productIdField = document.querySelector('[data-fui-id="pricing-form-product-id"]') ?? document.querySelector('[data-fui-id="pricing-form-au-product-id"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-product-id"]') ?? document.querySelector('[data-fui-id="pricing-form-row-product-id"]');
                const productPriceIndicationField = document.querySelector('[data-fui-id="pricing-form-price-indication"]') ?? document.querySelector('[data-fui-id="pricing-form-au-price-indication"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-price-indication"]') ?? document.querySelector('[data-fui-id="pricing-form-row-price-indication"]');
                const productMetersField = document.querySelector('[data-fui-id="pricing-form-meters"]') ?? document.querySelector('[data-fui-id="pricing-form-au-meters"]') ?? document.querySelector('[data-fui-id="pricing-form-uk-meters"]') ?? document.querySelector('[data-fui-id="pricing-form-row-meters"]');

                const productName = this.result.product;
                const productProfile = this.result.profile;
                const productLineal = this.round(this.result.lineal, 2);
                const productWeight = this.round(this.result.weight, 2);
                const productFixing = this.round(this.result.fixing, 2);
                const productOneCoat = this.round(this.result.oneCoat, 2);
                const productTwoCoats = this.round(this.result.twoCoats, 2);
                const { productId } = this.result;
                const productPriceIndication = this.result.priceIndication;
                const productMeters = this.result.meters;

                notesField.value = `${'What I will need\n\n' +
                    'Product: '}${productName}\n` +
                    `Product profile: ${productProfile}\n` +
                    `Lineal Meters: ${productLineal}\n` +
                    `Total approximate weight - KG: ${productWeight}\n` +
                    `Fixings - Pieces: ${productFixing}\n` +
                    `Oil - Litres (second coat to front face installed): ${productOneCoat}\n` +
                    `Oil - Litres (one coat all sides + two coats face): ${productTwoCoats}\n\n` +
                    'Please enter additional notes';
                productNameField.value = productName;
                productProfileField.value = productProfile;
                productLinealField.value = productLineal;
                productWeightField.value = productWeight;
                productFixingField.value = productFixing;
                productOneCoatField.value = productOneCoat;
                productTwoCoatsField.value = productTwoCoats;
                productIdField.value = productId;
                productPriceIndicationField.value = productPriceIndication;
                productIdField.value = productId;
                productMetersField.value = productMeters;
            });
        },

        reset() {
            this.selectedProductId = this.productId;
            this.selectedProfile = 0;
            this.meters = null;
            this.result = null;
        },
    },
};
</script>

const Address = () => {
    const $stateSelect = document.querySelectorAll('select.js-address-stateId');

    if ($stateSelect && $stateSelect.length) {
        $stateSelect.forEach((e) => {
            e.addEventListener('change', (ev) => {
                const $this = ev.target;
                const $closestAddressBox = $this.closest('.js-address-box');
                const $stateInput = $closestAddressBox.querySelector('input.js-address-stateName');

                $stateInput.value = $this.value;
            });
        });
    }
};

export {
    Address,
};

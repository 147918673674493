export default function productCsrf() {
    const forms = document.querySelectorAll('#productForm');

    forms.forEach((form) => {
        const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

        fetch('/actions/blitz/csrf/input')
            .then((result) => {
                return result.text();
            })
            .then((result) => {
                csrfInput.outerHTML = result;
            });
    });
}

import {
    defineComponent, h, nextTick, ref, watchEffect,
} from 'vue';

export const ProductAccessories = defineComponent({
    name: 'ProductAccessories',

    props: {
        variants: {
            type: Array,
            required: true,
        },
    },

    setup(props, { slots }) {
        const loading = ref(false);
        const selectedVariant = ref(props.variants[0]);

        const colours = ref([]);

        props.variants.forEach((variant) => {
            if (colours.value.some((colour) => { return colour.label === variant.colour.label; })) {
                return;
            }

            colours.value.push(variant.colour);
        });

        const selectedColour = ref(props.variants[0].colour.label);

        const sizes = ref([]);

        props.variants.forEach((variant) => {
            if (sizes.value.some((size) => { return size === variant.size; })) {
                return;
            }

            sizes.value.push(variant.size);
        });

        const selectedSize = ref('');

        const availableSizes = ref([]);

        let disabledBtn = true;

        watchEffect(() => {
            if (selectedColour.value) {
                availableSizes.value = [];

                props.variants.forEach((variant) => {
                    if (variant.colour.label !== selectedColour.value) {
                        return;
                    }

                    availableSizes.value.push(variant.size);
                });
            }

            if (selectedColour.value) {
                [selectedVariant.value] = props.variants.filter((variant) => {
                    if (selectedSize.value !== '') {
                        disabledBtn = false;
                        return selectedSize.value === variant.size && selectedColour.value === variant.colour.label;
                    }

                    return selectedColour.value === variant.colour.label;
                });
            }
        });

        const addToCart = (e) => {
            loading.value = true;

            nextTick(() => {
                e.target.closest('form').submit();
            });
        };

        const checkSizeStock = (size) => {
            console.log(size);
            const variant = props.variants.find((variant) => {
                return variant.size === size && variant.colour.label === selectedColour.value;
            });
            console.log(variant);

            return !variant?.inStock;
        };

        return () => {
            return h('div', {}, slots.default({
                loading,
                colours,
                sizes,
                selectedSize,
                selectedColour,
                availableSizes,
                selectedVariant,
                disabledBtn,
                addToCart,
                checkSizeStock,
            }));
        };
    },
});

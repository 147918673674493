// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Update the CSRF field on Formie forms
import formieCsrf from './components/formie-csrf';
import productCsrf from './components/product-csrf';

import { onClickOutside } from '@vueuse/core';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

import { Checkout } from './components/checkout';
import { Address } from './components/address';
import { Hubspot } from './components/hubspot';

import {
    Splide,
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

import { createPinia } from 'pinia';
const pinia = createPinia();

// Import all globally-available components here. Be responsible please.
import {
    TransitionRoot,
    TransitionChild,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    Dialog,
    DialogPanel,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
} from '@headlessui/vue';

import MapboxMap from '../vue/components/MapboxMap.vue';
import ProductSwatch from '@components/ProductSwatch.vue';
import ProfileSwatch from '@components/ProfileSwatch.vue';
import GeoModal from '@components/GeoModal.vue';
import FlickityCarousel from '@components/FlickityCarousel.vue';
import ArticleFilters from '@components/ArticleFilters.vue';
import NewsFilters from '@components/NewsFilters.vue';
import ProjectFilters from '@components/ProjectFilters.vue';
import ProductFilters from '@components/ProductFilters.vue';
import SampleFilters from '@components/SampleFilters.vue';
import ResourceFilters from '@components/ResourceFilters.vue';
import { ProductAccessories, ProductSamples, ProductCalculator } from '@components/products';

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        TransitionRoot,
        TransitionChild,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogOverlay: DialogOverlay,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        ProductSwatch,
        ProfileSwatch,
        ArticleFilters,
        NewsFilters,
        ProjectFilters,
        ProductFilters,
        SampleFilters,
        ResourceFilters,
        GeoModal,
        FlickityCarousel,
        ProductAccessories,
        ProductSamples,
        ProductCalculator,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
    },

    data() {
        return {
            showCountries: false,

            // Toggle the product swatches at the global Vue instance level, just to save us having to
            // pile everything for the modal into a Vue component. We kinda just want to keep it Twig
            // for ease-of-dev. As such, use an array to keep track of all open modals (for swatches).
            openProductModals: [],
            openFormModals: [],
            openProfileModals: [],
            openGeoModal: false,
            navActive: false,
            searchActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.
        formieCsrf();
        productCsrf();

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        Checkout();
        Address();
        Hubspot();

        onClickOutside();

        const filterForm = document.querySelector('#filter-form');

        if (filterForm) {
            document.querySelectorAll('.js-category-checkbox').forEach((link) => {
                link.addEventListener('click', (event) => {
                    filterForm.submit();
                });
            });
        }

        const menuItem = document.querySelectorAll('.js-menu-item');

        if (menuItem.length) {
            menuItem.forEach((e) => {
                e.addEventListener('click', (o) => {
                    const openedItems = document.querySelectorAll('.js-im-open');

                    if (openedItems.length) {
                        openedItems.forEach((s) => {
                            if (!e.classList.contains('js-im-open')) {
                                s.classList.remove('js-im-open');
                            }
                        });
                    }
                    this.showCountries = false;
                    e.classList.toggle('js-im-open');
                });
            });
        }

        const checkoutForm = document.getElementById('customer-info-form');

        if (checkoutForm) {
            const address1 = checkoutForm.querySelector('input[name="shippingAddress[address1]"]');

            const submitBtn = document.querySelector('button.btn-primary');

            if (address1.value.toLowerCase().includes('po box') || address1.value.toLowerCase().includes('p.o box')) {
                submitBtn.disabled = true;
            }

            address1.addEventListener('change', (e) => {
                if (address1.value.toLowerCase().includes('po box') || address1.value.toLowerCase().includes('p.o box')) {
                    submitBtn.disabled = true;
                } else {
                    submitBtn.disabled = false;
                }
            });
        }

        const cookieBtn = document.querySelector('.dismiss-cookie-message');
        const cookieBar = document.querySelector('.cookie-bar');

        if (cookieBar) {
            cookieBtn.addEventListener('click', (e) => {
                document.cookie = 'cookiemsg=cookiemsg; path=/; max-age=2592000;';
                cookieBar.classList.add('hidden');
            });
        }

        const qtyTotal = document.getElementById('order-qty');

        if (qtyTotal) {
            const cartData = this.getCartData().then((cartData) => {
                qtyTotal.innerText = `(${cartData.cart.totalQty})`;
                qtyTotal.classList.remove('loading');
            });
        }

        if (document.cookie.indexOf('GeoMateRedirectOverride') < 0) {
            const geoLocation = this.getGeoLocation().then((geoLocation) => {
                console.log(geoLocation);

                if (geoLocation) {
                    this.openGeoModal = true;
                }
            });
        }
    },

    methods: {
        isProductModalOpen(id) {
            return this.openProductModals[id]?.isOpen ? true : false;
        },

        getProductModalFromId(id) {
            return this.openProductModals[id];
        },

        setProductModal(id, value, index) {
            this.openProductModals[id] = {
                isOpen: value,
                activeTabIndex: index ?? 0,
            };
        },

        isFormModalOpen(id) {
            return this.openFormModals[id] ? true : false;
        },

        setFormModal(id, value) {
            this.openFormModals[id] = value;
            formieCsrf();
        },

        isProfileModalOpen(id) {
            return this.openProfileModals[id] ? true : false;
        },

        setProfileModal(id, value) {
            this.openProfileModals[id] = value;
        },

        toggleCountrySelect() {
            this.showCountries = !this.showCountries;

            const menuItem = document.querySelectorAll('.js-menu-item');

            menuItem.forEach((item) => {
                item.classList.remove('js-im-open');
            });
        },

        hideAll() {
            this.showCountries = false;

            const menuItem = document.querySelectorAll('.js-menu-item');

            menuItem.forEach((item) => {
                item.classList.remove('js-im-open');
            });
        },

        toggleSearch() {
            this.searchActive = !this.searchActive;

            this.$nextTick(() => {
                if (this.searchActive) {
                    const searchInput = document.querySelector('#search');

                    searchInput.focus();
                }
            });
        },

        async getCartData() {
            const response = await fetch('/actions/commerce/cart/get-cart/', {
                headers: {
                    accept: 'application/json',
                },
            });

            const data = await response.json();

            return data;
        },

        async getGeoLocation() {
            const response = await fetch('/actions/site-module/base/get-geolocation-info', {
                headers: {
                    accept: 'application/json',
                },
            });

            const data = await response.json();

            return data;
        },
    },
});

app.use(pinia);

app.mount('#app');

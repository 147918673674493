const Checkout = () => {
    const $billingAddressSameAsShipping = document.querySelector('input.js-address-billingAddressSameAsShipping');

    if ($billingAddressSameAsShipping) {
        $billingAddressSameAsShipping.addEventListener('change', (e) => {
            const $billingAddress = document.querySelector('.js-address-billing');

            if (!$billingAddress) {
                return;
            }

            const $requiredFields = $billingAddress.querySelectorAll('.js-required');

            if (!e.target.checked) {
                $billingAddress.classList.remove('hidden');

                $requiredFields.forEach((el) => {
                    el.required = true;
                });

                return;
            }

            $requiredFields.forEach((el) => {
                el.required = false;
            });

            $billingAddress.classList.add('hidden');
        });
    }

    const $shippingAddressId = document.querySelectorAll('input.js-shipping-address-id');
    const $shippingAddressFields = document.querySelector('.js-address-shipping-fields');

    $shippingAddressId.forEach(($item) => {
        $item.addEventListener('change', (e) => {
            if (!$shippingAddressFields) {
                return;
            }

            const $requiredFields = $shippingAddressFields.querySelectorAll('.js-required');

            if (e.target.value === '') {
                $shippingAddressFields.classList.remove('hidden');

                $requiredFields.forEach((el) => {
                    el.required = true;
                });

                return;
            }

            $requiredFields.forEach((el) => {
                el.required = false;
            });

            $shippingAddressFields.classList.add('hidden');
        });
    });

    const $billingAddressId = document.querySelectorAll('input.js-billing-address-id');
    const $billingAddressFields = document.querySelector('.js-address-billing-fields');

    $billingAddressId.forEach(($item) => {
        $item.addEventListener('change', (e) => {
            if (!$billingAddressFields) {
                return;
            }

            const $requiredFields = $billingAddressFields.querySelectorAll('.js-required');

            if (e.target.value === '') {
                $billingAddressFields.classList.remove('hidden');

                $requiredFields.forEach((el) => {
                    el.required = true;
                });

                return;
            }

            $requiredFields.forEach((el) => {
                el.required = false;
            });

            $billingAddressFields.classList.add('hidden');
        });
    });

    const $paymentMethods = document.querySelectorAll('input[name="paymentMethod"]');

    if ($paymentMethods.length) {
        $paymentMethods.forEach((paymentMethod) => {
            paymentMethod.addEventListener('click', (e) => {
                const content = document.querySelector(`[data-payment-method="${e.target.value}"]`);

                content.classList.remove('hidden');

                $paymentMethods.forEach((paymentMethod) => {
                    const content = document.querySelector(`[data-payment-method="${paymentMethod.value}"]`);

                    if (!paymentMethod.checked) {
                        content.classList.add('hidden');
                    }
                });
            });
        });
    }
};

export {
    Checkout,
};

const sendDataToHubspot = async(data, form) => {
    const firstName = data['shippingAddress[firstName]'];
    const lastName = data['shippingAddress[lastName]'];
    const phone = data['shippingAddress[phone]'];
    const persona = data['fields[role]'];
    const countryId = data['shippingAddress[countryId]'];
    let country = 'Australia';

    if (countryId == 158) {
        country = 'New Zealand';
    }

    const address = data['shippingAddress[address1]'];
    const city = data['shippingAddress[city]'];
    const state = document.getElementById('shipping-stateValue');
    const stateName = state.options[state.selectedIndex].text;

    const { email, formId, source } = data;

    const response = await fetch(`/actions/hubspot/register?firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}&persona=${persona}&country=${country}&region=${country}&state=${stateName}&address=${address}&city=${city}&formId=${formId}&source=${source}`);

    const responseData = await response.json();

    form.submit();
};

export const Hubspot = () => {
    const $form = document.getElementById('customer-info-form');

    if (!$form) {
        return;
    }

    $form.addEventListener('submit', async(event) => {
        event.preventDefault();

        const formData = new FormData(event.target, event.submitter);

        const data = {};

        formData.forEach((value, key) => {
            data[key] = value;
        });

        const response = await fetch('/actions/commerce/cart/update-cart', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if (responseData.error) {
            const errors = Object.values(responseData.errors);

            errors.forEach((error) => {
                console.error(error[0]);
            });

            return;
        }

        sendDataToHubspot(data, event.target);
    });
};

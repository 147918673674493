<template>
    <div>
        <slot
            :article-categories="articleCategories"
            :update-article-categories="updateArticleCategories"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    articleCategories: {
        type: Array,
        required: true,
    },
});

const articleCategories = ref(props.articleCategories);

const updateArticleCategories = (event) => {
    if (articleCategories.value.includes(event.target.value)) {
        articleCategories.value = articleCategories.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    articleCategories.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (articleCategories.value.length) {
        searchParams.append('category', articleCategories.value.join(','));
    }

    window.location.href = `/articles?${searchParams.toString()}`;
};

</script>

export default function formieCsrf() {
    const forms = document.querySelectorAll('form[id*="formie-form-"]');

    forms.forEach((form) => {
        const formHandle = form.querySelector('input[name="handle"]').value;
        const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

        fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
            .then((result) => { return result.json(); })
            .then((result) => { csrfInput.outerHTML = result.csrf.input; });
    });
}

<template>
    <div>
        <slot
            :timber-products="timberProducts"
            :update-timber-products="updateTimberProducts"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    timberProducts: {
        type: Array,
        required: true,
    },
});

const timberProducts = ref(props.timberProducts);

const updateTimberProducts = (event) => {
    if (timberProducts.value.includes(event.target.value)) {
        timberProducts.value = timberProducts.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    timberProducts.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (timberProducts.value.length) {
        searchParams.append('timbers', timberProducts.value.join(','));
    }

    window.location.href = `/products/samples?${searchParams.toString()}`;
};

</script>

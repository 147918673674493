<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
import Flickity from 'flickity';

export default {
    name: 'FlickityCarousel',

    props: {
        options: {
            default() {
                return {
                    prevNextButtons: true,
                    pageDots: true,
                };
            },
        },
    },

    mounted() {
        new Flickity(this.$el, this.options);
    },
};
</script>

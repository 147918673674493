<template>
    <div>
        <slot
            :products="products"
            :update-products="updateProducts"
            :series="series"
            :update-series="updateSeries"
            :resource-types="resourceTypes"
            :update-resource-types="updateResourceTypes"
            :how-to="howTo"
            :update-how-to="updateHowTo"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
    series: {
        type: Array,
        required: false,
    },
    resourceTypes: {
        type: Array,
        required: true,
    },
    howTo: {
        type: Array,
        required: true,
    },
});

const products = ref(props.products);

const updateProducts = (event) => {
    if (products.value.includes(event.target.value)) {
        products.value = products.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    products.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const series = ref(props.series);

const updateSeries = (event) => {
    if (series.value.includes(event.target.value)) {
        series.value = series.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    series.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};


const resourceTypes = ref(props.resourceTypes);

const updateResourceTypes = (event) => {
    if (resourceTypes.value.includes(event.target.value)) {
        resourceTypes.value = resourceTypes.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    resourceTypes.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const howTo = ref(props.howTo);

const updateHowTo = (event) => {
    if (howTo.value.includes(event.target.value)) {
        howTo.value = howTo.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    howTo.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (products.value.length) {
        searchParams.append('products', products.value.join(','));
    }

    if (series.value.length) {
        searchParams.append('series', series.value.join(','));
    }

    if (resourceTypes.value.length) {
        searchParams.append('resource-types', resourceTypes.value.join(','));
    }

    if (howTo.value.length) {
        searchParams.append('how-to', howTo.value.join(','));
    }

    window.location.href = `/resources?${searchParams.toString()}`;
};

</script>

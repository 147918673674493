<template>
    <div>
        <slot
            :product-types="productTypes"
            :update-product-types="updateProductTypes"
            :timber-products="timberProducts"
            :update-timber-products="updateTimberProducts"
            :applications="applications"
            :update-applications="updateApplications"
            :fixings="fixings"
            :update-fixings="updateFixings"
            :coatings="coatings"
            :update-coatings="updateCoatings"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    productTypes: {
        type: Array,
        required: true,
    },
    timberProducts: {
        type: Array,
        required: true,
    },
    applications: {
        type: Array,
        required: true,
    },
    fixings: {
        type: Array,
        required: false,
    },
    coatings: {
        type: Array,
        required: true,
    },
});

const productTypes = ref(props.productTypes);

const updateProductTypes = (event) => {
    if (productTypes.value.includes(event.target.value)) {
        productTypes.value = productTypes.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    productTypes.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const timberProducts = ref(props.timberProducts);

const updateTimberProducts = (event) => {
    if (timberProducts.value.includes(event.target.value)) {
        timberProducts.value = timberProducts.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    timberProducts.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};


const applications = ref(props.applications);

const updateApplications = (event) => {
    if (applications.value.includes(event.target.value)) {
        applications.value = applications.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    applications.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const fixings = ref(props.fixings);

const updateFixings = (event) => {
    if (fixings.value.includes(event.target.value)) {
        fixings.value = fixings.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    fixings.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const coatings = ref(props.coatings);

const updateCoatings = (event) => {
    if (coatings.value.includes(event.target.value)) {
        coatings.value = coatings.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    coatings.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (productTypes.value.length) {
        searchParams.append('types', productTypes.value.join(','));
    }

    if (timberProducts.value.length) {
        searchParams.append('timbers', timberProducts.value.join(','));
    }

    if (applications.value.length) {
        searchParams.append('application', applications.value.join(','));
    }

    if (fixings.value.length) {
        searchParams.append('fixings', fixings.value.join(','));
    }

    if (coatings.value.length) {
        searchParams.append('coating', coatings.value.join(','));
    }

    window.location.href = `${window.location.pathname}?${searchParams.toString()}`;
};

</script>
